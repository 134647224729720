import React from 'react'
import styled from 'styled-components'

import IndexLayout from '~/layouts'
import { SEO, Page, Container, Time, PostList, Typography } from '~/components'
import { SEOProps } from '~/components/SEO'
import { usePosts } from '~/hooks/usePosts'

const PostsPage = () => {
  const seo: SEOProps = {
    title: 'Posts',
    path: '/posts',
    article: false,
  }
  const posts = usePosts()
  return (
    <IndexLayout>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image}
        path={seo.path}
        article={seo.article}
      />
      <Page>
        <Container>
          <Typography variant="h1">Post Archive</Typography>
          <PostList posts={posts} />
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default PostsPage
